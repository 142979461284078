import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { firstBannerMed, secondBannerMed, thirdBannerMed, forthBannerMed } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  btnVisible?: boolean;
  showMenu?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedDiv: null | any;
  anchorElHome: boolean | null;
  anchorElTools: boolean | null;
  anchorElServices: boolean | null;
  anchorElhelp: boolean | null;
  cardDetails: any;
  medIDJourney: any;
  selectedMenu: number;
  menuItems: any;
  anchorEls: any;
  glowParams: any;
  addNewTask: boolean;
  createdTask: any;
  currentDate: any;
  showMessage: string;
  userName: string;
  medEdJourneyKnowledgeLists: any;
  isQuestionModal: boolean;
  startNewQuestions: boolean;
  questions: any;
  courseStart: boolean;
  continueLearning: any;
  formattedDuration: string;
  mouseHover: boolean;
  currentGlow: string;
  selectedDate: number;
  currentPage: any;
  questionsPerPage: any;
  medEdJourneyLists: any;
  selectMenuExplore: string;
  currentPageExplore: number;
  itemsPerPage: number;
  activeSlideIndex: number;
  sliderData: any;
  medEdExploreLists: any;
  isSortOpen: boolean;
  sortedExplore: any;
  isSearching: boolean;
  exploreSearch: string;
  exploreSearchedLists: any;
  currentTaskPage: number; // Current task page
  tasksPerPage: number;
  medEdCardDetails: any;
  activeDMed: any;
  currentPageDMed: any;
  dMedPerPage: any
  chartHourWeek: any;
  chartHourDay: any;
  chartHourMonth: any;
  chartHourYear: any;
  selectedTimeRange: 'day' | 'week' | 'month' | 'year',
  selectedOption: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Catalogue1Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  newTaskApiCallID: any;
  categoryApiCallID: any;
  continueLearningApiCallID: any;
  recommendedCardApiCallID: any;
  medEdJourneyKnowledgeApiCallID: any;
  questionBankListApiCallID: any;
  medEdJourneyApiCallID: any;
  medEdExploreApiCallID: any;
  exploreSortApiCallID: any;
  exploreSearchApiCallID: any;
  medEdKnowledgeCardApiCallID: any;
  chartHourDayApiCallID: any;
  chartHourWeekApiCallID: any;
  chartHourMonthApiCallID: any;
  chartHourYearApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedDiv: null,
      anchorElHome: null,
      anchorElTools: null,
      anchorElServices: null,
      anchorElhelp: null,
      selectedMenu: 1,
      cardDetails: [],
      medEdJourneyKnowledgeLists: [],
      medIDJourney: [],
      menuItems: [
        { id: "home", label: "Home", items: ["My Schedule", "My Files"] },
        { id: "tools", label: "My Tools", items: ["My Tools1", "My Tools"] },
        {
          id: "services",
          label: "My Services",
          items: [
            "Knowledge & Training Center",
            "Healthcare VR/AR & Gaming",
            "Marketplace",
            "Healthcare Research",
            "Healthcare Innovation & Enterpreneurship",
          ],
        },
        { id: "help", label: "Help Center", items: ["Help1"] },
      ],
      anchorEls: {},
      glowParams: {
        color: "#72efdd",
        rotate: -1,
      },
      addNewTask: false,
      createdTask: [],
      currentDate: new Date(),
      showMessage: "",
      userName: "",
      isQuestionModal: false,
      startNewQuestions: false,
      questions: [],

      courseStart: true,
      continueLearning: [],
      formattedDuration: "",
      mouseHover: false,
      currentGlow: "",
      selectedDate: -1,

      medEdJourneyLists: [],
      selectMenuExplore: "All",
      currentPageExplore: 1,
      itemsPerPage: 6,
      activeSlideIndex: 0,
      sliderData: [
        {
          id: 1,
          image: firstBannerMed,
          heading: "Dive into our world of MedEd Journeys!",
          desc: "Let's begin your educational adventure together.",
        },
        {
          id: 2,
          image: secondBannerMed,
          heading: "Expert-Led Journeys!",
          desc: "Our Journeys are designed by leading professionals in their fields.",
        },
        {
          id: 3,
          image: thirdBannerMed,
          heading: "Stay Updated!",
          desc: "Explore the latest in medicine with our Emerging Technologies Journeys.",
        },
        {
          id: 4,
          image: forthBannerMed,
          heading: "Consistency is key!",
          desc: "Dedicate at least 5–10 minutes daily to your latest journeys. Small steps, big results!",
        },
      ],
      medEdExploreLists: [],
      isSortOpen: false,
      sortedExplore: [],
      isSearching: false,
      exploreSearch: "",
      exploreSearchedLists: [],
      currentPage: 0,
      questionsPerPage: 3,
      currentTaskPage: 1, // Starting page
      tasksPerPage: 3,
      medEdCardDetails: [],
      activeDMed: '',
      currentPageDMed: 0,
      dMedPerPage: 8,
      selectedTimeRange: 'week',
      chartHourDay: {},
      chartHourWeek: {},
      chartHourMonth: {}, // example placeholder
      chartHourYear: {},
      selectedOption: 'daily',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.newTaskApiCallID) {
        this.setState({
          createdTask: responseJson.today_tasks.data,
          showMessage: responseJson.message,
        });
      } else if (apiRequestCallId === this.categoryApiCallID) {
        this.setState({ medIDJourney: responseJson.data });
      } else if (apiRequestCallId === this.recommendedCardApiCallID) {
        this.setState({ cardDetails: responseJson.recommended_courses });
      } else if (apiRequestCallId === this.medEdJourneyKnowledgeApiCallID) {
        this.setState({ medEdJourneyKnowledgeLists: responseJson.data });
      } else if (apiRequestCallId === this.medEdKnowledgeCardApiCallID) {
        this.setState({ medEdCardDetails: responseJson.data });
      } else if (apiRequestCallId === this.questionBankListApiCallID) {
        this.setState({ questions: responseJson.data });
      } else if (apiRequestCallId === this.continueLearningApiCallID) {
        this.setState({ continueLearning: responseJson.data });
      } else if (apiRequestCallId === this.medEdJourneyApiCallID) {
        this.setState({ medEdJourneyLists: responseJson.data });
      } else if (
        apiRequestCallId === this.medEdExploreApiCallID ||
        apiRequestCallId === this.exploreSortApiCallID
      ) {
        this.setState({ medEdExploreLists: responseJson.data });
      } else if (apiRequestCallId === this.exploreSearchApiCallID) {
        this.setState({ exploreSearchedLists: responseJson.data });
      } else if (apiRequestCallId === this.chartHourDayApiCallID) {
        this.setState({ chartHourDay: responseJson });
      } else if (apiRequestCallId === this.chartHourWeekApiCallID) {
        this.setState({ chartHourWeek: responseJson });
      } else if (apiRequestCallId === this.chartHourMonthApiCallID) {
        this.setState({ chartHourMonth: responseJson });
      } else if (apiRequestCallId === this.chartHourYearApiCallID) {
        this.setState({ chartHourYear: responseJson });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  setActiveSlideIndex = (e: any) => {
    this.setState({ activeSlideIndex: e });
  };

  handleMenuSelected = (text: string) => {
    this.setState({ selectMenuExplore: text });
  };

  handleExploreSort = () => {
    this.setState({ isSortOpen: !this.state.isSortOpen });
  };

  handleExploreSearch = (event: any) => {
    const searchTerm = event.target.value;
    this.setState(
      { exploreSearch: searchTerm, isSearching: !!searchTerm },
      () => {
        if (searchTerm) {
          this.exploreSearch(searchTerm); // Call API when there is a search term
        } else {
          this.setState({ exploreSearchedLists: [] }); // Clear results if input is empty
        }
      }
    );
  };


  exploreSearch = async (search: string) => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exploreSearchApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/search_categories?title=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleStopPropogate = (e: any) => {
    e.stopPropagation()
  }

  componentDidMount = async () => {
    await this.onClickCategory(this.state.activeDMed);
    await this.onRecommendedCard();
    let usrName = await getStorageData("userName");
    this.setState({ userName: usrName });
    await this.onMedEdKnowledgeLists();
    await this.listofQuestionBank();
    await this.onContinueLearning();
    await this.onNewTask();
    await this.listOfMedEdJourney();
    await this.listOfMedEdExplore();
    await this.onChartHourWeek();

    if ((this.props.id as any) === "3") {
      this.setState({ selectedMenu: 3 });
    }

    if ((this.props.id as any) === "2") {
      this.setState({ selectedMenu: 2 });
    }
  };

  onChartHourDay = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourDayApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourDayEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onChartHourWeek = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourWeekApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourWeekEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onChartHourMonth = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourMonthApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourMonthEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onChartHourYear = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.chartHourYearApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chartHourYearEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goToTermsAndConditions = () => {
    this.props.navigation.navigate("TermsAndConditions");
  };

  handlePageChange = (pageIndex: any) => {
    this.setState({ currentPage: pageIndex });
  };
  
  chunkItems = () => {
    const chunkedItems = [];
    for (let i = 0; i < this.state.medEdJourneyLists.length; i += this.state.dMedPerPage) {
      chunkedItems.push(this.state.medEdJourneyLists.slice(i, i + this.state.dMedPerPage));
    }
    return chunkedItems;
  };

  handlePageChangeDMed = (pageIndex: any) => {
    this.setState({ currentPageDMed: pageIndex });
  };

  getItemsForCurrentPage = () => {
    const chunkedItems = this.chunkItems();
    return chunkedItems[this.state.currentPage] || [];
  };

  // handlePageChangeTask = (newPage: number) => {
  //   this.setState({ currentTaskPage: newPage });
  // };

  getClassName(priority: string) {
    if (priority === 'low') {
      return 'greenCircle';
    } else if (priority === 'medium') {
      return 'yellowCircle';
    } else if (priority === 'high') {
      return 'redCircle';
    } else {
      return '';
    }
  }
  handleScheduleClick = () => {
    this.props.navigation.navigate("Scheduling");
  };

  onMenuItemClick = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "KnowledgeTrainingCenter"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onMedEdJourney = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "MedEdJourney"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
  onClickHome = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Catalogue1"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onRecommendedCard = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recommendedCardApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recommendedCardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onNewTask = async () => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newTaskApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/today_task?time_zone=${"Asia/Kolkata"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickCategory = async (name: any) => {
    this.setState({activeDMed: name})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdCardKnowledgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onMedEdKnowledgeLists = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdJourneyKnowledgeApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdJourneyKnowledgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listOfMedEdJourney = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdJourneyApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdJourneyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listOfMedEdExplore = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdExploreApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdExploreEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listofQuestionBank = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.questionBankListApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionBankListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onContinueLearning = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.continueLearningApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.continueLearningEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
